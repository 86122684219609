import Mustache from "mustache";

export const getRepliesContext = entries => {
  return entries.reduce((p, c, i) => {
    return {
      ...p,
      ...Object.entries(c).reduce(
        (test, [key, data]) => ({
          ...test,
          [`TS${i + 1}_${key}`]: data,
        }),
        {}
      ),
    };
  }, {});
};

export const insertVariablesIntoHtml = (html = "", entries) => {
  return Mustache.render(html, {
    REPORT_URL: window.location.href,
    ...getRepliesContext(entries),
  });
};
