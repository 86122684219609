import React, { useContext, useEffect } from "react";
import { UPDATE_CURRENT_SESSION_TEST_DATA } from "../../../../../store/actions";
import { Context } from "../../../../../store/store";
import { Typography } from "../../components";
import "./styles.scss";
import { insertVariablesIntoHtml } from "./utils";
import Visualization from "./visualization";

function TestResults() {
  const [state, dispatch] = useContext(Context);

  const {
    publicTest: { sets, startReportHtml, endReportHtml, entries, client },
  } = state;

  const renderScale = (setId, scale, i) => {
    const entry = entries.find(entry => entry.testSetId === setId) || {};
    const forming = entry[`S${i + 1}F`];
    return (
      <div>
        <Typography h1 center>
          {scale.scaleName}
        </Typography>
        <div
          className="tg_info"
          dangerouslySetInnerHTML={{ __html: scale.scaleReportInfo }}
        />
        <Visualization scale={scale} value={forming} />
        {forming && (
          <div
            className="tg_info"
            dangerouslySetInnerHTML={{
              __html: scale[`forming${forming}Report`],
            }}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    dispatch({
      type: UPDATE_CURRENT_SESSION_TEST_DATA,
      payload: { sessionId: null },
    });
  }, [dispatch]);

  return (
    <div className="tg_page tg_test-results-page">
      <div className="header">
        <div
          className="tg_info"
          dangerouslySetInnerHTML={{
            __html: insertVariablesIntoHtml(startReportHtml, entries),
          }}
        />
      </div>
      {!client?.disableIndividualReport && (
        <div className="tg_column">
          {sets.map((set, i) => (
            <div key={i} className="tg_set-result">
              <div
                className="tg_info"
                dangerouslySetInnerHTML={{ __html: set.info.reportInfo }}
              />
              <div>
                {set.scales.map((scale, i) => (
                  <div className="tg_test-result" key={i}>
                    {renderScale(set._id, scale, i)}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="footer">
        <div
          className="tg_info"
          dangerouslySetInnerHTML={{
            __html: insertVariablesIntoHtml(endReportHtml, entries),
          }}
        />
      </div>
    </div>
  );
}

export default TestResults;
