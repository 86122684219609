import { Box, MenuItem, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGet } from "../hooks";
import { API_URLS } from "../../../utils/api-constants";
import { useMemo } from "react";
import { groupBy, orderBy } from "lodash";

export const CLIENT_TESTS_COUNT_LIMIT = 10;

function TestsDropdown({ testIds, setFieldValue }) {
  const { id: clientId } = useParams();
  const getTests = useGet(`${API_URLS.TESTS}?clientId=${clientId}`);

  const menuList = useMemo(() => {
    if (getTests.data?.length > 0) {
      const grouped = groupBy(getTests.data, s => s.clientId || "");
      const entries = orderBy(Object.entries(grouped), [
        groupEntry => groupEntry[0].toLowerCase(),
      ]);

      return entries.map(([_, tests]) => {
        const menuItems = orderBy(tests, [test => test.name]).map(test => (
          <MenuItem key={test._id} value={test._id}>
            {`${test.name} ${test.languageId?.toUpperCase()}`}
          </MenuItem>
        ));
        return [menuItems];
      });
    }
  }, [getTests.data]);

  return Array.from({ length: CLIENT_TESTS_COUNT_LIMIT }, (_, i) => (
    <Box key={i} mb="15px" display="flex" justifyContent="center">
      <TextField
        variant="outlined"
        name={`testIds[${i}]`}
        value={testIds[i] || ""}
        select
        size="small"
        fullWidth
        label="Select"
        onChange={e => {
          setFieldValue(e.target.name, e.target.value);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {menuList}
      </TextField>
    </Box>
  ));
}
export default TestsDropdown;
