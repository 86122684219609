import React from "react";
import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { SUPPORTED_LANGUAGES } from "../../constants";

export function LanguageDropdown() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  return (
    <Grid container alignItems="center">
      <Grid item xs={2}>
        <Typography>{t("tool.addEdit.language", "Language")}</Typography>
      </Grid>
      <Grid item xs={10}>
        <FormControl fullWidth>
          <Select
            size="small"
            value={values.languageId || ""}
            variant="outlined"
            fullWidth
            displayEmpty
            onChange={e => {
              setFieldValue("languageId", e.target.value);
            }}
          >
            {SUPPORTED_LANGUAGES.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
