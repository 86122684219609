import {
  Box,
  CircularProgress,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { groupBy, orderBy } from "lodash-es";
import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { API_URLS } from "../../../utils/api-constants";
import { useGet } from "../hooks";
import { TEST_SETS_COUNT_LIMIT } from "../constants";

function SheetsSelection({ sheetIds, setFieldValue, ...rest }) {
  const { t } = useTranslation();
  const getSheets = useGet([API_URLS.SHEETS, { hierarchy: true }]);

  const menuList = useMemo(() => {
    if (getSheets.data?.length > 0) {
      const grouped = groupBy(getSheets.data, s => s.parent || "");
      const entries = orderBy(Object.entries(grouped), [
        groupEntry => groupEntry[0].toLowerCase(),
      ]);

      return entries.map(([parent, sheets]) => {
        const menuItems = orderBy(sheets, [
          sheet => sheet.title.toLowerCase(),
        ]).map(sheet => (
          <MenuItem
            key={sheet.id}
            value={sheet.id}
            style={{
              display: sheetIds.some(id => id === sheet.id)
                ? "none"
                : undefined,
            }}
          >
            {sheet.title}
          </MenuItem>
        ));
        const hiddenSubHead = menuItems.every(
          m => m.props?.style?.display === "none"
        );
        return [
          !hiddenSubHead && <ListSubheader>{parent}</ListSubheader>,
          menuItems,
        ];
      });
    }
  }, [sheetIds, getSheets.data]);

  if (getSheets.isLoading) {
    return (
      <Box align="center">
        <CircularProgress />
      </Box>
    );
  }
  if (getSheets.isError) {
    return <Typography color="secondary">Failed</Typography>;
  }

  return Array.from({ length: TEST_SETS_COUNT_LIMIT }, (_, i) => (
    <Box key={i} mb="15px" display="flex" justifyContent="center">
      <TextField
        name={`sheetIds[${i}]`}
        value={sheetIds[i] || ""}
        select
        variant="outlined"
        fullWidth
        label={t("tool.addEdit.sequence", `Sequence: {{sequence}}`, {
          sequence: i + 1,
        })}
        onChange={e => {
          setFieldValue(e.target.name, e.target.value);
        }}
        {...rest}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {menuList}
      </TextField>
    </Box>
  ));
}

export default memo(SheetsSelection);
